import React, { useState } from "react";
import Modal from "react-modal";
import ENoticeFormikWrapper, {
  PublishENoticeData,
} from "../FormikWrapper/ENoticeFormikWrapper";
import "./PublishENotice.scss";
import { NewENotice } from "../NewENotice/NewENotice";
import { publishTender } from "../Api/PostApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { catchError } from "../utils/catchError";
import { UpdateTenderNotice } from "../Api/PutApi";
import { PaymentPopUp } from "../PaymentPopUp/PaymentPopUp";
import moment from "moment";
export interface PublishENoticeProps {
  initialValuesProps?: PublishENoticeData;
  deposit?: boolean;
}
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "335px",
    width: "510px",
  },
};

const PublishENotice: React.FC<PublishENoticeProps> = ({
  initialValuesProps,
  deposit,
}) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tenderId, setTenderId] = useState<number>();
  const [publishLoad, setPublishLoad] = useState(false);
  const navigate = useNavigate();

  window.scrollTo(0, 0);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const PublishENotice = (
    values: PublishENoticeData,
    resetForm: Function,
    isUserVerified: boolean
  ) => {
    const cloned = {
      ...values,
      notice_category: values.notice_category?.value,
      publishing_date: moment(values.publishing_date).format("YYYY-MM-DD"),

    };
    if (values.saved) {
      setLoading(true);
    } else {
      setPublishLoad(true);
    }

    const formData: any = new FormData();
    formData.append("payload", JSON.stringify(cloned));
    formData.append("notice_type", "E-NOTICE");

    publishTender(formData)
      .then((res) => {
        if (res.status === 200) {
          if (values.saved) {
            navigate(`/?tab=4`);
          } else {
            if (Boolean(isUserVerified)) {
              navigate(`/?tab=2`);
            } else {
              toggleModal?.();
            }
          }
          setTenderId(res.data.data.tender_notice_id);
          toast.success(res.data.status.status_message);
          resetForm();
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        if (values.saved) {
          setLoading(false);
        } else {
          setPublishLoad(false);
        }
      });
  };

  const EditENotice = (
    values: PublishENoticeData,
    id: string | number | undefined
  ) => {
    if (values.saved) {
      setLoading(true);
    } else {
      setPublishLoad(true);
    }

    const cloned = {
      ...values,
      notice_category: values.notice_category?.value,
      publishing_date: moment(values.publishing_date).format("YYYY-MM-DD"),
    };
    const formData: any = new FormData();

    formData.append("payload", JSON.stringify(cloned));
    formData.append("notice_type", "E-NOTICE");
    UpdateTenderNotice(formData, id)
      .then((res) => {
        setTenderId(res.data.data.tender_notice_id);
        toast.success(res.data.status.status_message);
        if (values.saved) {
          navigate(`/?tab=4`);
        } else {
          if (!deposit) {
            toggleModal?.();
          } else {
            navigate(`/?tab=2`);
          }
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        if (values.saved) {
          setLoading(false);
        } else {
          setPublishLoad(false);
        }
      });
  };

  return (
    <>
      {(tenderId || !deposit) && (
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel="My dialog"
          style={customStyles}
          ariaHideApp={false}
        >
          <PaymentPopUp
            toggleModal={toggleModal}
            tender_notice_id={tenderId}
            loading={loading}
          />
        </Modal>
      )}
      <div className="quotations-wrapper bids-page">
        <div className="container">
          <div className="box card">
            <div className="title-note">
              <h3 className="head-titile">
                {initialValuesProps ? "Edit your E-Notice" : "Publish E-Notice"}
              </h3>
            </div>

            <div className="form-section">
              <div className="W-100">
                <ENoticeFormikWrapper
                  PublishENotice={PublishENotice}
                  initialValuesProps={initialValuesProps}
                  EditENotice={EditENotice}
                >
                  <div className="Stepper">
                    <div className="card-section">
                      <NewENotice loading={loading} publishLoad={publishLoad} />
                    </div>
                  </div>
                </ENoticeFormikWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublishENotice;
