import { useContext, createContext, useState } from "react";

export const SearchContext = createContext<any>(null);

export interface SearchQuery {
  location: string;
  organization_category: string;
  date: string;
  procurement_type: string;
  notice_category: string;
  project_category: string;
  title: string;
  budget: string;
  source: string;
}

export const SearchProvider = ({ children }: any) => {
  const initialQuery: SearchQuery = {
    organization_category: "",
    location: "",
    date: "",
    procurement_type: "",
    notice_category: "",
    project_category: "",
    title: "",
    budget: "", 
    source: ""
  };

  const [query, setQuery] = useState<SearchQuery>(initialQuery);
  const [page, setPage] = useState(1);

  const resetQuery = () => {
    setQuery(initialQuery);
  };

  return (
    <SearchContext.Provider
      value={{
        query,
        setQuery,
        page,
        setPage,
        resetQuery,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
export const useSearch = () => {
  const searchContext = useContext(SearchContext);
  if (!searchContext) {
    return "useSearch must be used with SearchContext";
  }
  return searchContext;
};
export default SearchProvider;
