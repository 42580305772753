import { useEffect, useState } from "react";
import Select from "react-select";
import "./GalleryView.scss";

export const GalleryView = (props?: any) => {
  const [view, setView] = useState<string>("list");

  useEffect(() => {
    props?.getView(view);
  }, [view]);

  useEffect(() => {
    setView(props?.viewType);
  }, [props?.viewType]);

  return (
    <div className="galleryview-section">
      <div className="view-card">
        <Select
          className="select-tab"
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          placeholder="Select View"
          options={props?.selectOptions}
          value={props?.selectOptions?.find((item: any) => item.value === view)}
          onChange={(e) => {
            setView(e?.value || "");
          }}
        />
      </div>
    </div>
  );
};
