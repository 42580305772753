import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { postBOQ, postDocument } from "../Api/PostApi";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { catch500Error } from "../utils/catchError";
import {
  concatWithComma,
  convertToFormData,
  encryptData,
  ifAnyOfJointVentureFieldIsFilled,
  isNoticeCategoryEOI,
  isNoticeCategoryStandingList,
} from "../../utils/services";
import { DocumentResponse } from "../EditBids/EditBids";
import { BidDocumentsTab } from "../Context/BidContext/BidContext";
import { requiredBidValidation } from "./BidalidationSchemas";
import { BidDetailTabs } from "../BidDetailStepper/BidDetailStepper";
import { BankDetail } from "../BidDetailPage/BidDetailPage";
import { DetailBid } from "../BidDetails/BidDetails";
import { ResponseBoq, ResponseBoqObject } from "../utils/interface";

export interface IBidDocuments {
  tender_notice_id: string;
  type: string;
  document_needed: string;
  filledDocuments: BidDocumentsTab; // Replace 'string' with the actual type of filledDocuments
  catalogueFiles: string;
}

interface IBidJointVenture {
  add_venture: boolean;
  joint_venture_name: string;
  venture_agreement: string;
  power_of_attorney: string;
  partner_document: string;
  tender_notice_id: string;
  type: string;
  filledDocuments: BidDocumentsTab;
}

export interface IBidBoq {
  tender_notice_id: string;
  boq: ResponseBoqObject[]; // Replace 'string' with the actual type of boq_details
  grand_total: number; // Replace 'number' if it's a different type
  additional_column_names: string[];
}
interface IBidBoqDetails {
  boqData: IBidBoq;
  boqFinancialDoc: {
    [key: string]: string;
  };
}

interface IBidFee {
  voucher: string;
  type: string;
  tender_notice_id: string;
  bid_bond_security_amount: string;
  filledDocuments: BidDocumentsTab;
  bid_fee_voucher?: string;
  publisher_bid_fee: boolean;
  bid_fee_voucher_date?: string;
}

export type FormikSetValues = (
  values: React.SetStateAction<BidDocumentInitialValues>,
  shouldValidate?: boolean | undefined
) => void;

export interface BidDocumentInitialValues {
  documents: IBidDocuments;
  jointVenture: IBidJointVenture;
  // boq_details: IBidBoq;
  boq_details: IBidBoqDetails;
  bidFee: IBidFee;
}
export const useBidFormikContext = () => {
  const formik = useFormikContext<BidDocumentInitialValues>();
  if (!formik) {
    throw new Error("useBidFormikContext must be used within a Formik");
  }
  return formik;
};

export interface FormikWrapperProps {
  children: any;
  documentNeed: string;
  resDocument: DocumentResponse;
  boq: ResponseBoq;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  reFetchData: (() => void) | undefined;
  catalogueFiles: string;
  bankDetail: BankDetail;
  bidDetail: DetailBid;
  boqFinancialFiles?: string;
  // boqFinancialResDocs?:IBoqCatalogueFiles
  optionalDoc?: string;
}

export const dynamicallyCreateDocumentInitialValues = (
  documentNeed = ''
) => {
  if (!documentNeed) return;
  let obj: { [key: string]: string } = {};
  if (!documentNeed) return obj;
  documentNeed.split(",").forEach((value) => {
    obj[value] = "";
  });
  return obj;
};

const BidFormikWrapper: React.FC<FormikWrapperProps> = ({
  children,
  documentNeed = '',
  resDocument,
  catalogueFiles,
  boq,
  page,
  setPage,
  reFetchData,
  bankDetail,
  bidDetail,
  boqFinancialFiles,
  optionalDoc,
}) => {
  const tender_notice_id = useParams();
  const initialvalues: BidDocumentInitialValues = {
    documents: {
      tender_notice_id: tender_notice_id.id as string,
      type: "Document",
      document_needed: documentNeed,
      catalogueFiles: catalogueFiles,
      filledDocuments: {
        ...resDocument.bid_documents,
        ...resDocument.boq_catalogue_files,
      },
      ...dynamicallyCreateDocumentInitialValues(documentNeed),
      ...dynamicallyCreateDocumentInitialValues(catalogueFiles ?? ""),
      ...dynamicallyCreateDocumentInitialValues(optionalDoc ?? ""),
    },
    jointVenture: {
      add_venture: ifAnyOfJointVentureFieldIsFilled(
        resDocument.joint_venture_documents
      ),
      joint_venture_name:
        resDocument?.joint_venture_documents?.joint_venture_name || "",
      venture_agreement: "",
      power_of_attorney: "",
      partner_document: "",
      tender_notice_id: tender_notice_id.id as string,
      type: "Joint-Venture",
      filledDocuments: {
        ...resDocument.joint_venture_documents,
      },
    },
    boq_details: {
      boqData: {
        tender_notice_id: tender_notice_id.id as string,
        boq: boq?.boq_details,
        grand_total: boq?.boq_sum + boq?.vat_amount,
        additional_column_names: [],
      },
      boqFinancialDoc: {
        ...dynamicallyCreateDocumentInitialValues(
          (boqFinancialFiles as string) ?? ""
        ),
      },
    },
    bidFee: {
      voucher: "",
      bid_fee_voucher: "",
      type: "Fee",
      tender_notice_id: tender_notice_id.id as string,
      bid_bond_security_amount: bankDetail.bid_bond_security_amount as string,
      filledDocuments: {
        ...resDocument.fee,
      },
      publisher_bid_fee: !bidDetail?.systemBidFee,
      bid_fee_voucher_date: resDocument?.fee?.bid_fee_voucher_date,
    },
  };

  const skipBidFeePostApi = (formData: IBidFee) => {
    let skip = true;
    const isBond =
      bankDetail &&
      parseFloat(bankDetail.bid_bond_security_amount as string) > 0;
    const isBid = !bidDetail?.systemBidFee;

    if (isBond && isBid && formData?.voucher && formData?.bid_fee_voucher)
      skip = false;
    if (isBond && formData?.voucher) skip = false;
    if (isBid && formData?.bid_fee_voucher) skip = false;
    return skip;
  };

  const bidFeeDocument = (
    formData: IBidFee,
    setLoading: Function
    // setValues: FormikSetValues,
    // values: BidDocumentInitialValues
  ) => {
    const page = isNoticeCategoryStandingList(bidDetail?.notice_category)
      ? BidDetailTabs.BID_FEE
      : BidDetailTabs.BOQ;
    const newFormdata = convertToFormData(formData);
    setLoading(true);

    if (skipBidFeePostApi(formData)) {
      setPage(page);
      setLoading(false);
    } else {
      postDocument(newFormdata)
        .then((res) => {
          setLoading(false);
          toast.success(res.data.status.status_message);
          setPage(page);
          reFetchData?.();
        })
        .catch((err) => {
          catch500Error(
            err.response.status,
            err.response.data.status.status_message
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const submitDocument = (
    formData: any,
    page: number,
    setLoading: Function
  ) => {
    setLoading(true);
    const newFormdata = convertToFormData(formData);

    postDocument(newFormdata)
      .then((res) => {
        setLoading(false);
        toast.success(res.data.status.status_message);
        setPage(page);
        reFetchData?.();
      })
      .catch((err) => {
        catch500Error(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const BoqPost = (data: IBidBoqDetails, setLoading: Function) => {
    setLoading(true);
    const newFormdata = convertToFormData(data?.boqFinancialDoc);
    newFormdata.append("bid_boq", encryptData(JSON.stringify(data?.boqData)));
    postBOQ(newFormdata)
      .then((res) => {
        setLoading(false);
        toast.success(res.data.status.status_message);
        reFetchData?.();
      })
      .catch((err) => {
        catch500Error(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => setLoading(false));
  };

  const DocumentSaveUpload = (
    values: BidDocumentInitialValues,
    setLoading: Function
    // setValues: FormikSetValues,
    // bidDetail: DetailBid
  ) => {
    let page = BidDetailTabs.BID_FEE;
    const bidDocs = dynamicallyCreateDocumentInitialValues(documentNeed);
    const catalogueDocs = dynamicallyCreateDocumentInitialValues(
      catalogueFiles ?? ""
    );
    const optionalDocs = dynamicallyCreateDocumentInitialValues(
      optionalDoc ?? ""
    );
    const allDocs = { ...bidDocs, ...catalogueDocs, ...optionalDocs };

    const isEmpty = Object.keys(allDocs).every(
      (key) => !(values?.documents as any)[key]
    );

    if (isNoticeCategoryEOI(bidDetail.notice_category))
      page = BidDetailTabs.DOCUMENT;

    if (isEmpty) {
      setPage(page);
      setLoading(false);
    } else {
      submitDocument(
        values.documents,
        page,
        setLoading
        // ResetDocumentValues(values, setValues, bidDetail)
      );
    }
  };

  const jointVentureUpload = (
    values: BidDocumentInitialValues,
    setLoading: Function
    // setValues: FormikSetValues
  ) => {
    const page = BidDetailTabs.DOCUMENT;
    if (!values.jointVenture.add_venture) {
      setLoading(false);
      return setPage(page);
    }
    submitDocument(
      values.jointVenture,
      page,
      setLoading
      // resetJointVentureValues(values, setValues)
    );
  };

  // const ResetDocumentValues = (
  //   values: BidDocumentInitialValues,
  //   setValues: FormikSetValues,
  //   bidDetail: DetailBid
  // ) => {
  //   if (isNoticeCategoryEOI(bidDetail.notice_category)) {
  //     navigate("/my-bids");
  //   }
  //   const resetValues = {
  //     ...values,
  //     documents: {
  //       ...values.documents,
  //       ...dynamicallyCreateDocumentInitialValues(
  //         values.documents.document_needed
  //       ),
  //       ...dynamicallyCreateDocumentInitialValues(
  //         values.documents.catalogueFiles
  //       ),
  //     },
  //   };
  //   setValues(resetValues);
  // };

  // const resetJointVentureValues = (
  //   values: BidDocumentInitialValues,
  //   setValues: FormikSetValues
  // ) => {
  //   const resetValues = {
  //     ...values,
  //     jointVenture: {
  //       ...values.jointVenture,
  //       venture_agreement: "",
  //       power_of_attorney: "",
  //       partner_document: "",
  //     },
  //   };
  //   setValues(resetValues);
  // };

  return (
    <Formik
      initialValues={initialvalues}
      onSubmit={(
        values: typeof initialvalues,
        { setSubmitting, setValues }
      ) => {
        switch (page) {
          case BidDetailTabs.JOINT_VENTURE:
            jointVentureUpload(values, setSubmitting);
            break;
          case BidDetailTabs.DOCUMENT:
            DocumentSaveUpload(values, setSubmitting);
            break;
          case BidDetailTabs.BID_FEE:
            bidFeeDocument(values.bidFee, setSubmitting);
            break;
          case BidDetailTabs.BOQ:
            BoqPost(values.boq_details, setSubmitting);
            break;
          default:
            return;
        }
      }}
      validationSchema={requiredBidValidation(
        page,
        concatWithComma(documentNeed ?? "", catalogueFiles ?? ""),
        bidDetail.additional_attributes.map((each) => each.column),
        boqFinancialFiles || ""
      )}
      validateOnChange={true}
      validateOnBlur={true}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default BidFormikWrapper;
