import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nepaliAmountFormat } from "nepali-number";
import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Award } from "../../Pages/Results/Results";
import { isBidder, isNoticeCategoryResult } from "../../utils/services";
import { ignoreTenderBid, saveTenderBid, tenderBid } from "../Api/PostApi";
import { useAuth } from "../Context/AuthContext";
import { ModalContext } from "../Context/ModalContext/ModalContext";
import { TenderCardAction } from "../TenderCardAction/TenderCardAction";
import { catchError } from "../utils/catchError";
import "./TenderCard.scss";
import { ResponseTenderCard } from "./TenderCardGroup";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

export interface Props {
  toggleModal?: () => void;
}

export interface ProcurementType {
  value: number;
  label: string;
}

export interface TenderCardProps {
  ignore: boolean;
  setIgnore: Dispatch<SetStateAction<boolean>>;
  savebids?: boolean;
  dataValue?: ResponseTenderCard;
  handleOnClickBid?: (tenderId: number) => void;
  handleOnClickSave?: (id: number) => void;
  handleOnClickTitle?: (data: ResponseTenderCard) => void;
}

export const TenderCard: React.FC<TenderCardProps> = ({
  ignore,
  setIgnore,
  savebids,
  dataValue,
  handleOnClickBid,
  handleOnClickSave,
  handleOnClickTitle,
}) => {
  const { role, isAuthenticate, eligible, userData } = useAuth();
  const { TogglePopUpFlags } = useContext(ModalContext);
  const [showAll, setShowAll] = useState(false);

  //This function ensures bidder clicking the bid button for first time or not
  const FirstBidCheck = (tender_id: string | number | undefined) => {
    const data = {
      tender_notice_id: tender_id,
    };
    tenderBid(data)
      .then((res) => {
        if (res.status === 202) {
          toast.error(res.data.status.status_message);
        }
        if (res.status === 200) {
          window.open(`/tender-bid/${tender_id}`, "_blank");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return TogglePopUpFlags("login");
        }
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  const saveBids = (tender_id: string | number | undefined) => {
    saveTenderBid(tender_id)
      .then((res) => {
        toast.success("Bid Saved Successfully");
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  const shareUrl = `${dataValue?.private
    ? `bids/view/${dataValue?.id}`
    : `tender-notice/${dataValue?.id}`
    }`;



  const ignoreBids = (tender_id: string | number | undefined) => {
    ignoreTenderBid(tender_id)
      .then((res) => {
        toast.success("Bid Ignored Successfully");
        setIgnore(!ignore);
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  return (
    <div className="tender-card">
      <div className="card">
        <div className="card-section p-section">
          <div className="tender_number-content">
            <div className="tender-header">
              <div className="tag-info">
                <img
                  className="map-icon"
                  src={require("../../images/location_fill.svg").default}
                  alt="images"
                />

                <div className="paragraph-tender">
                  <p className="tender-contents">
                    <span className="tender-bold">{dataValue?.district}</span>
                  </p>
                </div>
              </div>
              <div className="border-line"></div>
              <div className="tag-info">
                <p className=" tender-contents">
                  <span className="tender-bold">
                    {dataValue?.notice_category}
                  </span>
                </p>
              </div>
              <div className="border-line"></div>
              <div className="tag-info">
                <p className=" tender-contents">
                  {dataValue?.source && (
                    <span className="tender-bold">
                      Source: {dataValue?.source}
                    </span>
                  )}
                </p>
              </div>
            </div>

            <div className="tender-document-data">
              <div className="tender_number-number">
                <p className="tender_number-no">
                  TID :
                  <span className="tender-bold">
                    {dataValue?.tender_number}
                  </span>
                </p>
              </div>

              <TenderCardAction
                shareUrl={shareUrl}
                boq={dataValue?.boq}
                tender_id={dataValue?.id}
                savebids={savebids}
                title={dataValue?.title}
                image={dataValue?.image}
                ppmoFileUrl={dataValue?.ppmo_file_url}
                terms={dataValue?.terms}
                notes={dataValue?.notes}
                show_boq_item_rate={dataValue?.show_boq_item_rate}
                opening_date={dataValue?.opening_date}
                category_type={dataValue?.category_type}
                remaining_days={dataValue?.remaining_days}
                handleOnClickBid={handleOnClickBid}
                privateBid={dataValue?.private}
                additional_attributes={dataValue?.additional_attributes}
              />
            </div>
          </div>
          <div>
            <p
              className="tender-title"
              onClick={() => {
                handleOnClickTitle?.(dataValue as ResponseTenderCard);
              }}
            >
              {dataValue?.title}
            </p>
          </div>
          <div>
            {dataValue?.private ? (
              <>
                {dataValue?.user === userData?.id ? (
                  <Link to={`/`} target="_blank">
                    <p className="sub-title">{dataValue?.public_entity_name}</p>
                  </Link>
                ) : (
                  <Link to={`/publisher/${dataValue?.user}`} target="_blank">
                    <p className="sub-title">{dataValue?.public_entity_name}</p>
                  </Link>
                )}
              </>
            ) : (
              <p className="sub-title">{dataValue?.public_entity_name}</p>
            )}
          </div>
          <div className="tender-border"></div>

          <div className="card-wrapper">
            <div className="d-flex">
              <div className="bid-before">
                <div className="tag-info">
                  <img src={require("../../images/bid.png")} alt="images" />

                  <p className="tender-contents">
                    Published Date:
                    <span className="tender-bold">
                      {dataValue?.publishing_date}
                    </span>
                  </p>
                  {dataValue?.remaining_days !== null && (
                    <>
                      <div className="border-line"></div>
                      <p className="tender-contents">
                        Apply Before:
                        <span className="tender-bold">
                          {dataValue?.submission_date?.slice(0, 10)}
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </div>
              {dataValue?.remaining_days !== null && (
                <div className="days-remain">
                  <p className="days-count">
                    {Number(dataValue?.remaining_days) <= 0 ? (
                      <span>Expired</span>
                    ) : (
                      <span>
                        {dataValue?.remaining_days + " Days Remaining"}
                      </span>
                    )}
                  </p>
                </div>
              )}
            </div>

            <div className="bid-before-first-section  bidbefore-section">
              {(!isAuthenticate || isBidder(role)) &&
                dataValue?.private &&
                dataValue?.specification_terms_format &&
                Number(dataValue?.remaining_days) > 0 && (
                  <div className="btn-combination">
                    <div className="mg-btn-left">
                      <button
                        className="transparent-btn"
                        onClick={() => {
                          handleOnClickBid?.(dataValue?.id as number);
                        }}
                      >
                        <img
                          className="btn-img-icon-size"
                          src={require("../../images/bidblue.png")}
                          alt="images"
                        />
                        Apply Bid
                      </button>
                    </div>
                  </div>
                )}
              {savebids ? (
                <div>
                  {isAuthenticate && (
                    <div className="mg-btn-left">
                      <button
                        className="save-bid-btn"
                        onClick={() => {
                          ignoreBids(dataValue?.id);
                        }}
                      >
                        Ignore
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {(!isAuthenticate || isBidder(role)) &&
                    (!dataValue?.private ||
                      !dataValue?.specification_terms_format) && (
                      <div className="mg-btn-left">
                        <button
                          className="save-bid-btn"
                          onClick={() => {
                            handleOnClickSave?.(dataValue?.id as number);
                          }}
                        >
                          <FontAwesomeIcon className="icon-bg" icon={faSave} />
                          Save Bid
                        </button>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
          {dataValue?.award_result && dataValue?.award_result?.length > 0 && (
            <div>
              <div className="work-tender-border"></div>
              <div className="d-flex justify-content-between">
                {isNoticeCategoryResult(
                  dataValue?.notice_category as string
                ) ? (
                  <div className="work-img mg-right-8">
                    <img
                      className="img"
                      src={require("../../images/award.png")}
                      alt="images"
                    />
                    <span className="work-header">Awarded to: </span>
                  </div>
                ) : (
                  <div className="work-img mg-right-8">
                    <i className="bi bi-bag img"></i>
                    <span className="work-header">Work: </span>
                  </div>
                )}
                <div
                  className="see-all-section"
                  onClick={() => {
                    setShowAll(!showAll);
                  }}
                >
                  {showAll ? (
                    <p className="see-all-text">
                      View Less{" "}
                      <Icon className="mdi-chevron" path={mdiChevronUp} />
                    </p>
                  ) : (
                    <p className="see-all-text">
                      View More{" "}
                      <Icon className="mdi-chevron" path={mdiChevronDown} />
                    </p>
                  )}
                </div>
              </div>
              {showAll && (
                <ul className="award-section">
                  {dataValue?.award_result?.map(
                    (award: Award, index: number) => {
                      return (
                        <li className="award-content" key={index}>
                          {`Tender ID: ${award.tender_number ?? "-"}, ` +
                            (award.company ||= "N/A") +
                            `(Rs. ${award.price
                              ? nepaliAmountFormat(award.price)
                              : "N/A"
                            })`}
                        </li>
                      );
                    }
                  )}
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
