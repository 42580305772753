import { Form, Formik, useFormikContext } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  BidCategory,
  TenderAwardType,
  FileSize,
} from "../../utils/EnumConstants";
import { addDaysInDate } from "../utils/generic";
import { useAuth } from "../Context/AuthContext";
import { isNoticeCategoryStandingList } from "../../utils/services";
import { ITenderAttributes } from "../utils/interface";

interface TenderFormikWrapperProps {
  children: any;
  page?: number | string;
  setPage?: any;
  initialValuesProps: any;
  PublishTenderNotice: (
    values: PublishTenderData,
    resetForm: Function,
    isUserVerified: boolean
  ) => void;
  EditTenderNotice: (
    values: PublishTenderData,
    id: string | number | undefined
  ) => void;
}

export interface BOQItem {
  blank: boolean;
  item_description: string;
  unit: string;
  quantity: string;
  rate: number;
  fixed: boolean;
  additional_attributes: any[];
}

interface AdditionalColumData {
  [key: string]: string;
}
export interface TenderBOQ {
  category: string;
  item: Array<BOQItem>;
  additional_columns: AdditionalColumData[];
}

export interface AdditionalAttributes {
  column: string;
}
export interface PublishTenderData {
  boq_catalog_name?: string;
  same_as_previous: boolean;
  notice_category: { label: string; value: number } | null;
  procurement_type: number | string;
  project_category: [];
  bid_bond_security: boolean;
  bid_bond_security_amount: string;
  excelfile: string;
  bid_bond_validity: number;
  publishing_date: Date;
  additional_attributes: AdditionalAttributes[];
  boq: TenderBOQ[];
  district: string;
  submission_date: Date;
  bank_name?: string;
  has_bid_fee: boolean;
  publisher_bid_fee: number | null;
  branch?: string;
  account_number?: string;
  account_name?: string;
  saved?: boolean;
  show_boq_item_rate: boolean;
  document_needed: {
    bid_letter: boolean;
    declaration_letter: boolean;
    applicants_information: boolean;
    experience: boolean;
    capacity: boolean;
    qualification: boolean;
    citizenship: boolean;
  };
  tender_number: string;
  catalogue_extra?: boolean;
  boq_details?: {
    boq_total: number;
    vat: number;
    grand_total: number;
  };
  notes?: string;
  terms?: string;
  category?: string;
  award_type?: string;
  specification_terms_format?: string;
  is_vat: boolean;
  is_update?: boolean;
  boq_file?: string;
  work_locations: [];
  estimated_cost: number | string;
  hide_bidder: Boolean;
  has_boq_financial_documents?: boolean;
  boq_financial_documents?: string;
  has_optional_document?: boolean;
  optional_documents?: string;
  is_main?: boolean;
}

const currentDate = new Date();
currentDate.setDate(currentDate.getDate());
const defaultPublishingDate = new Date(currentDate.toISOString().split("T")[0]);
const defaultSubmissionDate = addDaysInDate(new Date(defaultPublishingDate), 5);

export const initialValues: PublishTenderData = {
  bid_bond_security: false,
  bid_bond_security_amount: "",
  excelfile: "",
  catalogue_extra: false,
  tender_number: "",
  district: "",
  same_as_previous: false,
  notice_category: null,
  procurement_type: "",
  project_category: [],
  bid_bond_validity: 0,
  publishing_date: defaultPublishingDate,
  submission_date: defaultSubmissionDate,
  bank_name: "",
  branch: "",
  account_number: "",
  account_name: "",
  has_bid_fee: false,
  publisher_bid_fee: null,
  show_boq_item_rate: false,
  saved: false,
  document_needed: {
    bid_letter: false,
    declaration_letter: false,
    applicants_information: false,
    experience: false,
    capacity: false,
    qualification: false,
    citizenship: false,
  },
  boq_catalog_name: "",
  specification_terms_format: "",
  is_vat: true,
  boq_file: "",
  additional_attributes: [],
  boq: [
    {
      category: "",
      item: [
        {
          item_description: "",
          unit: "",
          quantity: "",
          rate: 0,
          fixed: false,
          blank: true,
          additional_attributes: [],
        },
      ],
      additional_columns: [],
    },
  ],
  boq_details: {
    boq_total: 0,
    vat: 0,
    grand_total: 0,
  },
  notes: "",
  terms: "",
  category: BidCategory.SINGLE_ENVELOPE,
  award_type: TenderAwardType.SINGLE_BIDDER,
  is_update: false,
  work_locations: [],
  estimated_cost: "",
  hide_bidder: false,
  has_boq_financial_documents: false,
  boq_financial_documents: "",
  has_optional_document: false,
  optional_documents: "",
};

const validationSchemaTwo = Yup.object({
  boq_file: Yup.mixed()
    .test(
      "boq_file",
      FileSize.MAX_FILE_SIZE_WORD,
      (value) => !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
    )
    .test("fileFormat", "CSV only", (value) => {
      return (
        !value || ["text/csv", "application/vnd.ms-excel"].includes(value.type)
      );
    }),

  boq_financial_documents: Yup.string()
    .when("has_boq_financial_documents", {
      is: (val: boolean) => val === true,
      then: Yup.string().required("Required"),
    })
    .matches(
      /^(?![\s\S]*[^\w, -]+)[\s\S]*?$/,
      "Invalid Financial Documents Name"
    )
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces"),

  boq: Yup.array().when(["boq_file", "has_boq_financial_documents"], {
    is: (boqFile: string, hasBOQFinancialDoc: boolean) => {
      if (hasBOQFinancialDoc) {
        return false;
      }
      if (!boqFile) {
        return true;
      }
    },
    then: Yup.array().of(
      Yup.object({
        category: Yup.string().required("Required"),
        item: Yup.array().of(
          Yup.object({
            item_description: Yup.string().required("Required"),
            unit: Yup.string().required("Required"),
            quantity: Yup.string()
              .required("Required")
              .test("is-numeric", "Invalid Quantity!", (value: any) =>
                /^[0-9]+(\.[0-9]{1,4})?$/.test(value)
              ),
          })
        ),
      })
    ),
    otherwise: Yup.array().notRequired(),
  }),
});

export const useTenderFormik = () => {
  const formik = useFormikContext<PublishTenderData>();
  if (!formik) {
    throw new Error(
      "useTenderFormik must be used within a TenderFormikWrapper"
    );
  }
  return formik;
};

const TenderFormikWrapper: React.FC<TenderFormikWrapperProps> = ({
  children,
  page,
  setPage,
  initialValuesProps,
  PublishTenderNotice,
  EditTenderNotice,
}) => {
  const { userData } = useAuth();

  const validatePublishingDate = () => {
    const today = new Date();
    today.setDate(today.getDate());
    if (initialValuesProps) {
      const requireDate = new Date(initialValuesProps.publishing_date);
      const validationDate = requireDate.toLocaleDateString();
      return validationDate;
    }
    return today.toLocaleDateString();
  };

  let validationSchemaOne = Yup.object({
    estimated_cost: Yup.string().test(
      "Estimated cost",
      "Required",
      (value, context) => {
        const noticeCateory = context.parent.notice_category;
        // Check if noticeCategory is provided and not standing list
        if (
          noticeCateory &&
          isNoticeCategoryStandingList(noticeCateory.label)
        ) {
          return true;
        }

        // Check if value is a valid number greater than 0
        if (Number(value) <= 0 || isNaN(Number(value))) {
          return false;
        }
        return true;
      }
    ),

    work_locations: Yup.array()
      .of(Yup.string().required("Required"))
      .min(1, "Please choose at least one location"),
    bid_bond_security: Yup.boolean().required("❗ Check at least one"),
    tender_number: Yup.string().required("Required"),
    notice_category: Yup.object()
      .shape({
        value: Yup.number().required("Required"),
        label: Yup.string().required("Required"),
      })
      .nullable()
      .required("Required"),

    project_category: Yup.mixed().when(["notice_category", "is_update"], {
      is: (val: ITenderAttributes, isUpdate: Boolean) =>
        val &&
        !isUpdate &&
        val !== undefined &&
        isNoticeCategoryStandingList(val.label),
      then: Yup.array().min(1, "Required"),
      otherwise: Yup.array()
        .min(1, "Required"),
    }),

    publishing_date: Yup.date()
      .min(
        validatePublishingDate(),
        `Publishing date must be greater or equal to ${validatePublishingDate()}`
      )
      .required("Required"),

    submission_date: Yup.date()
      .min(
        Yup.ref("publishing_date"),
        "Submission date cannot be before publishing date"
      )
      .test(
        "is-submission-date-valid",
        "Submission date must be at least 5 days after publishing date",
        function (value) {
          const publishingDate = this.resolve(Yup.ref("publishing_date"));
          const minSubmissionDate = new Date(publishingDate as string);
          minSubmissionDate.setDate(minSubmissionDate.getDate() + 5);
          return (value as Date) >= minSubmissionDate;
        }
      )
      .required("Submission date is required"),

    publisher_bid_fee: Yup.mixed().when("has_bid_fee", {
      is: (val: boolean) => val === true,
      then: Yup.mixed().typeError("Invalid Bid Fee").required("Required"),
      otherwise: Yup.mixed().notRequired(),
    }),

    document_needed: Yup.object({
      bid_letter: Yup.boolean(),
      declaration_letter: Yup.boolean(),
    }).test(
      "document_needed",
      //@ts-ignore
      null,
      (obj) => {
        if (obj.bid_letter || obj.declaration_letter) {
          return true;
        }
        return new Yup.ValidationError(
          "❗ Check atleast one from Bid letter or Declaration Letter",
          null,
          "document_needed.bid_letter"
        );
      }
    ),

    bid_bond_validity: Yup.number().when("bid_bond_security", {
      is: (val: boolean) => {
        return val === true;
      },
      then: Yup.number().required("Required").min(1, "Required"),
      otherwise: Yup.number().notRequired(),
    }),

    bid_bond_security_amount: Yup.string().when("bid_bond_security", {
      is: (val: boolean) => val === true,
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),

    bank_name: Yup.string().when(
      ["bid_bond_security", "has_bid_fee", "publisher_bid_fee"],
      {
        is: (
          bidBondSecurity: boolean,
          hasBidFee: boolean,
          publisher_bid_fee: number
        ) =>
          bidBondSecurity === true ||
          (hasBidFee === true && publisher_bid_fee > 0),
        then: Yup.string()
          .matches(/^(?![\s\S]*[^\w -]+)[\s\S]*?$/, "Invalid Bank Name")
          .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
          .required("Required"),
        otherwise: Yup.string().notRequired(),
      }
    ),

    branch: Yup.string().when(
      ["bid_bond_security", "has_bid_fee", "publisher_bid_fee"],
      {
        is: (
          bidBondSecurity: boolean,
          hasBidFee: boolean,
          publisher_bid_fee: number
        ) =>
          bidBondSecurity === true ||
          (hasBidFee === true && publisher_bid_fee > 0),
        then: Yup.string()
          .matches(/^(?![\s\S]*[^\w -]+)[\s\S]*?$/, "Invalid Branch Name")
          .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
          .required("Required"),
        otherwise: Yup.string().notRequired(),
      }
    ),

    account_number: Yup.string().when(
      ["bid_bond_security", "has_bid_fee", "publisher_bid_fee"],
      {
        is: (
          bidBondSecurity: boolean,
          hasBidFee: boolean,
          publisher_bid_fee: number
        ) =>
          bidBondSecurity === true ||
          (hasBidFee === true && publisher_bid_fee > 0),
        then: Yup.string()
          .min(9, "Invalid Account Number")
          .max(25, "Invalid Account Number")
          .matches(/^[0-9-]+$/, "Invalid Account Number")
          .required("Required"),
        otherwise: Yup.string().notRequired(),
      }
    ),

    account_name: Yup.string().when(
      ["bid_bond_security", "has_bid_fee", "publisher_bid_fee"],
      {
        is: (
          bidBondSecurity: boolean,
          hasBidFee: boolean,
          publisher_bid_fee: number
        ) =>
          bidBondSecurity === true ||
          (hasBidFee === true && publisher_bid_fee > 0),
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
      }
    ),

    specification_terms_format: Yup.mixed()
      .test(
        "specification_terms_format",
        FileSize.MAX_FILE_SIZE_WORD,
        (value) => !value || (value && value.size <= FileSize.MAX_FILE_SIZE)
      )
      .test("isRequired", "Required", (value, context) => {
        const { is_update } = context.parent;
        if (is_update) {
          return true;
        }
        return !!value;
      })
      .test("fileFormat", "PDF only", (value) => {
        return !value || ["application/pdf"].includes(value.type);
      }),

    boq_catalog_name: Yup.string()
      .when("catalogue_extra", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
      })
      .matches(/^(?![\s\S]*[^\w, -]+)[\s\S]*?$/, "Invalid Catalog Name")
      .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces"),

    optional_documents: Yup.string()
      .when("has_optional_document", {
        is: (val: boolean) => val === true,
        then: Yup.string().required("Required"),
      })
      .matches(/^(?![\s\S]*[^\w, -]+)[\s\S]*?$/, "Invalid Catalog Name")
      .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces"),

    // hide_bidder: Yup.string().required("Required"),
  });

  const { id } = useParams();

  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={initialValuesProps ? initialValuesProps : initialValues}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (
            page === 2 ||
            isNoticeCategoryStandingList(values.notice_category.label)
          ) {
            setSubmitting(true);
            initialValuesProps
              ? EditTenderNotice(values, id)
              : PublishTenderNotice(values, resetForm, userData.is_verified);
          } else {
            setPage?.(2);
          }
        }}
        validationSchema={
          page === 1 ? validationSchemaOne : validationSchemaTwo
        }
      >
        <Form>{children}</Form>
      </Formik>
    </>
  );
};

export default TenderFormikWrapper;
