import React, { useContext, useState } from "react";
import fallbackLogoUrl from "../../images/bolpatra logo-full.png";
import { ResultShimmer } from "../../Pages/Results/ResultShimmer";
import { isBidder } from "../../utils/services";
import { useAuth } from "../Context/AuthContext";
import { ModalContext } from "../Context/ModalContext/ModalContext";
import { ImageWithFallback } from "../ImageWithFallback/ImageWithFallback";
import { NoDataFound } from "../NoDataFound/NoDataFound";
import "../TenderCard/TenderCard.scss";
import { ResponseTenderCard } from "../TenderCard/TenderCardGroup";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ETenderSideShimmer } from "../ETenderSideShimmer/ETenderSideShimmer";
export interface ETenderCardProps {
  date?: string;
  data?: Array<ResponseTenderCard>;
}

type ETenderTheme = "Primary" | "Secondary";

export interface ETenderSideProps {
  handleViewAll?: () => void;
  sectionTitle?: string;
  tenderCard?: ResponseTenderCard[];
  noticeType?: string;
  loading?: Boolean;
  theme?: ETenderTheme;
}

export const ETenderSide: React.FC<ETenderSideProps> = ({
  handleViewAll,
  sectionTitle,
  tenderCard,
  noticeType,
  loading,
  theme,
}) => {
  const { TogglePopUpFlags } = useContext(ModalContext);
  const { role, isAuthenticate, userData } = useAuth();
  const navigateToNoticeDetail = (tender_id: string | number | undefined, privateVal: boolean) => {
    if (!tender_id) return;
    if (noticeType === "E-Notice") {
      window.open(`/e-notice/view/${tender_id}`, "_blank");
    } else {
      const shareUrl = `${privateVal
        ? `bids/view/${tender_id}`
        : `tender-notice/${tender_id}`
        }`;
      window.open(shareUrl, "_blank");
    }
  };

  const handleOnClickTitle = (tenderId: number, privateVal: boolean) => {
    if (isAuthenticate || privateVal) {
      if (
        isBidder(role) &&
        userData?.plan_remaining_days === 0 &&
        !privateVal
      ) {
        var planUrl = `${window.location.origin}/pricing-plan`;
        window.open(planUrl, "_blank");
      } else {
        navigateToNoticeDetail(tenderId, privateVal);
      }
    } else {
      TogglePopUpFlags("login");
    }
  };

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: tenderCard && tenderCard?.length < 6 ? tenderCard?.length : 6,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    autoplaySpeed: 3000,
    loop: true,
  };

  return (
    <div className="cardwrapper-section ebid-card">
      <div>
        <div className={`side-ebid-section`}>
          <div
            className={`d-flex justify-content-between side-ebid-title-section ${theme?.toLowerCase()}`}
          >
            <h4 className="title">{sectionTitle}</h4>
            <button
              className="text-btn"
              onClick={() => {
                handleViewAll?.();
              }}
            >
              View All
            </button>
          </div>
          {loading || !tenderCard ? (
            <ETenderSideShimmer />
          ) : (
            <>
              {tenderCard && tenderCard.length > 0 ? (
                <div className="side-ebid-body">
                  <Slider {...settings}>
                    {tenderCard.map(
                      (item: ResponseTenderCard, index: number) => {
                        return (
                          <div
                            key={index}
                            className="side-ebid-content-section"
                          >
                            <React.Fragment key={index}>
                              <div className="tender-card">
                                <ImageWithFallback
                                  src={item?.user_info?.additional_info?.logo || ""}
                                  fallbackSrc={fallbackLogoUrl}
                                  alt="Company Logo"
                                />
                                <div
                                  className="side-tender-body"
                                  title={item?.title}
                                >
                                  <p
                                    className="tender-title"
                                    onClick={() => {
                                      handleOnClickTitle(item?.id, item?.private);
                                    }}
                                  >
                                    {item?.title}
                                  </p>
                                  <p className="sub-title">
                                    {item?.public_entity_name}
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                          </div>
                        );
                      }
                    )}
                  </Slider>
                </div>
              ) : (
                <div>
                  <NoDataFound
                    text1="No tenders found"
                    img={require("../../images/nodata.png")}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
