export const convertDateToISOString = (date: Date) => {
  return date.toISOString().substring(0, 10);
};

export const formatSelectOptions = (datas: Object) => {
  if (!datas) return;
  return Object.entries(datas).map(([key, value]) => {
    //@ts-ignore
    const options = value.map((item: string, index: number) => {
      return { label: item, value: item };
    });
    return {
      label: key,
      options: [{ label: "All", value: "All-" + key }, ...options],
    };
  });
};

export const addDaysInDate = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  const currenttime = new Date();
  newDate.setDate(newDate.getDate() + days);
  //set hours to current time
  newDate.setHours(currenttime.getHours());
  newDate.setMinutes(currenttime.getMinutes());
  return newDate;
};
