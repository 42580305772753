import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { isNoticeCategoryStandingList } from "../../utils/services";
import { getBankDetail } from "../Api/GetApi";
import { publishTender } from "../Api/PostApi";
import { UpdateTenderNotice } from "../Api/PutApi";
import { BankDetail } from "../BidDetailPage/BidDetailPage";
import TenderFormikWrapper, {
  PublishTenderData,
  useTenderFormik,
} from "../FormikWrapper/TenderFormikWrapper";
import { NewTender } from "../NewTender/NewTender";
import { NoteComponent } from "../NoteComponent/NoteComponent";
import { PaymentPopUp } from "../PaymentPopUp/PaymentPopUp";
import BoqTable from "../boqTable/boqTable";
import { catchError } from "../utils/catchError";
import "./PublishTender.scss";
import moment from "moment";
export interface PublishTenderStepperProps {
  initialValuesProps?: PublishTenderData;
  deposit?: boolean;
  resDocument?: any;
  additionalColumnsProps?: string[];
}
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "335px",
    width: "510px",
  },
};

const PublishTenderStepper: React.FC<PublishTenderStepperProps> = ({
  initialValuesProps,
  deposit,
  resDocument,
  additionalColumnsProps,
}) => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type") || "notice";
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [publishLoad, setPublishLoad] = useState(false);
  const [bankDetail, setBankDetail] = useState<BankDetail>();
  const [additionalColumns, setAdditionalColumns] = useState<string[]>([]);

  const editStandingListQuotation =
    initialValuesProps &&
    isNoticeCategoryStandingList(
      initialValuesProps.notice_category?.label ?? ""
    ) &&
    !initialValuesProps.is_main;

  useEffect(() => {
    if (additionalColumnsProps && additionalColumnsProps?.length > 0) {
      setAdditionalColumns(additionalColumnsProps);
    }
  }, [additionalColumnsProps]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const fetchBankDetail = () => {
    setLoading(true);
    getBankDetail()
      .then((res) => {
        setBankDetail(res.data.data);
      })
      .catch((err) => {
        toast.error("Previous bank detail is unavailable");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [tenderId, setTenderId] = useState<number>();
  const navigate = useNavigate();

  const EditTenderNotice = (
    values: PublishTenderData,
    id: string | number | undefined
  ) => {
    if (values.saved) {
      setLoading(true);
    } else {
      setPublishLoad(true);
    }
    delete values.catalogue_extra;

    const cloned = {
      ...values,
      notice_category: values.notice_category?.value,
      publishing_date: moment(values.publishing_date).format("YYYY-MM-DD"),
    };
    const formData: any = new FormData();
    const specification_terms_format: any = cloned.specification_terms_format;
    const boq_file: any = cloned.boq_file;

    formData.append("specification_terms_format", specification_terms_format);
    formData.append("boq_file", boq_file);
    formData.append("payload", JSON.stringify(cloned));
    let typeenum = "E-BIDDING"
    if (searchParams.get("type") === "enlistment") {
      typeenum = "ENLISTMENT";
    }
    formData.append("notice_type", typeenum);
    UpdateTenderNotice(formData, id)
      .then((res) => {
        toast.success(res.data.status.status_message);
        if (!values.saved) {
          if (!deposit) {
            toggleModal?.();
          } else {
            navigate("/");
          }
        } else {
          let url = "";
          if (
            isNoticeCategoryStandingList(values.notice_category?.label ?? "")
          ) {
            url = `/my-tenders/edit/${id}?active_tab=1&type=enlistment`;
          } else {
            url = `/my-tenders/edit/${id}?active_tab=2`;
          }
          navigate(url);
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        if (values.saved) {
          setLoading(false);
        } else {
          setPublishLoad(false);
        }
      });
  };

  const PublishTenderNotice = (
    values: PublishTenderData,
    resetForm: Function,
    isUserVerified: boolean
  ) => {
    const cloned = {
      ...values,
      notice_category: values.notice_category?.value,
      publishing_date: moment(values.publishing_date).format("YYYY-MM-DD"),
    };
    delete cloned.boq_details;
    if (values.saved) {
      setLoading(true);
    } else {
      setPublishLoad(true);
    }
    delete cloned.catalogue_extra;
    const formData: any = new FormData();
    const specification_terms_format: any = cloned.specification_terms_format;
    const boq_file: any = cloned.boq_file;

    formData.append("specification_terms_format", specification_terms_format); // 'file' represents the actual file object
    formData.append("boq_file", boq_file); // 'file' represents the actual file object

    // Serialize the raw payload as JSON and append it as a form field
    formData.append("payload", JSON.stringify(cloned));
    let type = "E-BIDDING"
    if (searchParams.get("type") === "enlistment") {
      type = "ENLISTMENT";
    }
    formData.append("notice_type", type);
    publishTender(formData)
      .then((res) => {
        if (res.status === 200) {
          if (!values.saved) {
            if (Boolean(isUserVerified)) {
              navigate(`/`);
            } else {
              toggleModal?.();
            }
          } else {
            let url = "";
            if (
              isNoticeCategoryStandingList(values.notice_category?.label ?? "")
            ) {
              url = `/my-tenders/edit/${res.data.data.tender_notice_id}?active_tab=1&type=enlistment`;
            } else {
              url = `/my-tenders/edit/${res.data.data.tender_notice_id}?active_tab=2`;
            }
            navigate(url);
          }
          setTenderId(res.data.data.tender_notice_id);
          toast.success(res.data.status.status_message);
          resetForm();
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        if (values.saved) {
          setLoading(false);
        } else {
          setPublishLoad(false);
        }
      });
  };

  window.scrollTo(0, 0);

  useEffect(() => {
    if (editStandingListQuotation) {
      setPage(2);
    } else {
      setPage(Number(searchParams.get("active_tab")) || 1);
    }
    fetchBankDetail();
  }, []);

  const BoqTableContent = () => {
    const formikConfig = useTenderFormik(); // Access the Formik configuration from context
    return (
      <BoqTable
        page={page}
        setPage={setPage}
        loading={loading}
        publishLoad={publishLoad}
        edit={initialValuesProps ? true : false}
        additionalColumns={additionalColumns}
        setAdditionalColumns={setAdditionalColumns}
        formikConfig={formikConfig}
      />
    );
  };

  return (
    <>
      {(tenderId || !deposit) && (
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel="My dialog"
          style={customStyles}
          ariaHideApp={false}
        >
          <PaymentPopUp
            toggleModal={toggleModal}
            tender_notice_id={tenderId}
            loading={loading}
          />
        </Modal>
      )}

      <div className="quotations-wrapper bids-page">
        <div className="container">
          <div className="box card">
            <div className="title-note">
              <h3 className="head-titile">
                {initialValuesProps ? "Edit your Notice" : "Publish New Notice"}
              </h3>
              <p className="note">
                {initialValuesProps && deposit && (
                  // <span>Note:Each bid can be edited only once.</span>
                  <NoteComponent note="Published tender can be edited only once." />
                )}
              </p>
            </div>

            <div className="form-section">
              <div className="W-100">
                <TenderFormikWrapper
                  page={page}
                  setPage={setPage}
                  initialValuesProps={initialValuesProps}
                  PublishTenderNotice={PublishTenderNotice}
                  EditTenderNotice={EditTenderNotice}
                >
                  <div className="Stepper">
                    <div className="card-section">
                      <div className="form-section">
                        {!editStandingListQuotation && (
                          <button
                            type="button"
                            className={page === 1 ? " prime-btn" : " white-btn"}
                            onClick={() => {
                              setPage(1);
                            }}
                          >
                            Notice Details
                          </button>
                        )}

                        {!isNoticeCategoryStandingList(type) && (
                          <button
                            type="button"
                            disabled={initialValuesProps ? false : true}
                            className={page === 2 ? " prime-btn" : " white-btn"}
                            onClick={() => {
                              setPage(2);
                            }}
                          >
                            Financial
                          </button>
                        )}
                      </div>
                      {page === 1 && (
                        <NewTender
                          page={page}
                          setPage={setPage}
                          bankDetail={bankDetail}
                          resDocument={resDocument}
                          type={type}
                          loading={loading}
                          publishLoad={publishLoad}
                        />
                      )}
                      {page === 2 && <BoqTableContent />}
                    </div>
                  </div>
                </TenderFormikWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublishTenderStepper;
